.custom-switch.custom-control {
  background: none !important;
  border: none !important;
  margin: 10px;
}

.carousel-vod-title {
  color: black;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.carousel-vod-title:hover {
  color: #145388;
}
.carousel-vod-title-playing {
  color: #145388;
}

.carousel-vod-item {
  color: #909090;
  cursor: pointer;
}
.carousel-vod-item:hover {
  color: #145388;
  box-shadow: 0px 0px 4px 4px #145388;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.carousel-vod-item:hover svg {
  color: inherit;
  background: white;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
.carousel-vod-item-playing {
  color: #145388;
  box-shadow: 0px 0px 4px 4px #145388;
}

.carousel-vod-icon-playing {
  color: #145388;
  background: transparent;
}

.carousel-disabled {
  color: #909090 !important;
  cursor: auto !important;
}
.carousel-disabled:hover {
  color: #909090 !important;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.wrap-and-break {
  word-wrap: break-word;
  word-break: break-word;
}

.progress {
  height: 100% !important;
}

.overlay-question {
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-align: center;
  color: yellow;
  font-size: 30px;
  font-weight: 600;
  margin: 0;
}

.overlay-note,
.overlay-answer {
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-align: center;
  color: yellow;
  font-size: 16px;
}

.fake-navlink:hover {
  text-decoration: initial;
  color: #145388 !important;
  cursor: pointer;
}

.webinar-overlay:hover {
  opacity: 0.95 !important;
  transition: opacity 0.5s !important;
}

.feedback-hover:hover {
  border: 1px solid #ced4da !important;
}
